<template>
    <div id="new-client">
        <v-toolbar flat>
          <v-toolbar-title>
            <v-btn rounded @click="$router.go(-1)" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
            {{ company.end_company_name }}
          </v-toolbar-title>
          <v-spacer />
          <v-menu offset-y min-width="160">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click.stop="addNewRequest()">
                <v-list-item-icon><v-icon small>mdi-checkbox-multiple-marked-outline</v-icon></v-list-item-icon>新規依頼
              </v-list-item>
              <v-list-item @click="$router.push({ name: 'admin:request_index', params: { fillter: 'all' }, query: { end_user_id: end_user_id, end_company_name: end_company_name } })">
                <v-list-item-icon><v-icon small>mdi-format-list-bulleted-square</v-icon></v-list-item-icon>依頼一覧
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-tabs v-model="selectedTabs" show-arrows="" center-active>
          <!-- tabs button -->
          <v-tab class="ml-5"><v-icon left>mdi-domain</v-icon>クライアント情報</v-tab>
          <v-tab><v-icon left>mdi-file-sign</v-icon>契約情報</v-tab>
          <v-tab><v-icon left>mdi-account-multiple</v-icon>アカウント</v-tab>

          <!-- tab contents cLient info -->
          <v-tab-item>
              <v-divider />
              <v-toolbar flat dense>
                <v-spacer />
                <v-btn color="" text @click="$router.push({name: 'admin:client_edit_form'})"><v-icon left >mdi-pencil</v-icon>編集</v-btn>
              </v-toolbar>
            <v-container>

              <v-row class="flex-row-reverse">
                <v-col cols="12" md="3">
                  <!-- dashbord info -->
                  <v-row dense class="flex-sm-column">
                    <v-col cols="5" md="12">
                    <v-card>
                      <v-card-title class="pa-3">契約満了まで</v-card-title>
                      <v-divider />
                      <v-card-text v-if="company.current_end_user_plan" class="text-h5 text-center">
                        <span class="unit">あと</span><strong class="primary--text">{{ diffDate(company.current_end_user_plan.end_contract_date) }}</strong><span class="unit">日</span>
                      </v-card-text>
                      <v-card-text v-else class="text-h5 text-center">
                        <span class="unit">有効な契約がありません。</span>
                      </v-card-text>
                    </v-card>
                    </v-col>
                    <v-col cols="7" md="12">
                    <v-card v-if="company.current_end_user_plan && company.current_end_user_plan.plan_mst.contract_model == 'point'">
                      <v-card-title class="pa-3">残りポイント</v-card-title>
                      <v-divider />
                      <v-card-text class="text-h5 text-center">
                        <strong class="primary--text">{{ company.current_end_user_plan.remainder_point }}</strong><span class="unit">Pt</span> / {{ company.current_end_user_plan.max_point }}<span class="unit">Pt</span>
                      </v-card-text>
                    </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="9">
                  <!-- company info -->
                  <v-alert v-if="company.end_user_admin_memo" border="left" colored-border type="info" elevation="2" icon="mdi-note-text-outline">
                    <pre>{{ company.end_user_admin_memo }}</pre>
                  </v-alert>
                  <v-card class="">
                    <v-card-title>{{ company.end_company_name }}</v-card-title>
                    <v-card-subtitle>{{ company.end_company_kana }}</v-card-subtitle>
                    <v-divider />
                    <v-card-text>
                      <v-row dense>
                        <v-col col="12" md="12">
                          <h4>住所</h4>
                          <p>〒&nbsp;{{ company.zip_code }}&nbsp;{{ company.address }}</p>
                        </v-col>
                        <v-col cols="12" md="6" v-if="company.phone && company.phone !== 'undefined'">
                          <h4>電話番号</h4>
                          <p>
                            <v-icon size="22" left>mdi-deskphone</v-icon>{{ company.phone }}
                          </p>
                        </v-col>
                        <v-col cols="12" md="6" v-if="company.fax && company.fax !== 'undefined'">
                            <h4>FAX番号</h4>
                            <p>
                              <v-icon size="22" left>mdi-fax</v-icon>{{ company.fax }}
                            </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <!-- manager info -->
                  <v-card class="">
                    <v-card-title>担当者</v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12">
                          <h4>サポート担当者</h4>
                          <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-list>
                            <v-list-item>
                              <v-list-item-avatar>
                                <LoginAvatar :user="managers.main" :size="40" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{ managers.main.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ managers.main.kana }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action-text class="ml-0">
                                  <v-tooltip top v-if="managers.main.email">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon @click.stop="copyClipboard(managers.main.email)"><v-icon small v-bind="attrs" v-on="on">mdi-email</v-icon></v-btn>
                                    </template>
                                    <span>{{managers.main.email}}</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="managers.main.phone">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon @click.stop="copyClipboard(managers.main.phone)"><v-icon small v-bind="attrs" v-on="on">mdi-cellphone</v-icon></v-btn>
                                    </template>
                                    <span>{{managers.main.phone}}</span>
                                  </v-tooltip>
                              </v-list-item-action-text>
                            </v-list-item>
                            </v-list>
                          </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" v-if="managers.sub">
                            <h4>サブ担当者</h4>
                            <v-list>
                              <v-row dense>
                                <v-col cols="12" sm="6" md="6" v-for="item of managers.sub" :key = item.id>
                                  <v-list-item>
                                    <v-list-item-avatar>
                                      <LoginAvatar :user="item" :size="40" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                                      <v-list-item-subtitle>{{ item.kana }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action-text class="ml-0">
                                        <v-tooltip top v-if="item.email">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon @click.stop="copyClipboard(item.email)"><v-icon small v-bind="attrs" v-on="on">mdi-email</v-icon></v-btn>
                                          </template>
                                          <span>{{item.email}}</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="item.phone">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon @click.stop="copyClipboard(item.phone)"><v-icon small v-bind="attrs" v-on="on">mdi-cellphone</v-icon></v-btn>
                                          </template>
                                          <span>{{item.phone}}</span>
                                        </v-tooltip>
                                    </v-list-item-action-text>
                                  </v-list-item>
                                  <v-divider />
                                </v-col>
                              </v-row>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <!-- other info -->
                  <v-card v-if="company.production_environment || company.test_environment">
                    <v-card-title>その他の情報</v-card-title>
                    <v-divider />
                    <v-card-text>
                      <template v-if="company.production_environment">
                        <h4>本番サーバー</h4>
                        <pre v-linkified>{{company.production_environment}}</pre>
                      </template>
                      <template v-if="company.test_environment">
                        <h4>テストサーバー</h4>
                        <pre v-linkified>{{company.test_environment}}</pre>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

            </v-container>
          </v-tab-item>

          <!-- tab contents contract -->
          <v-tab-item>
              <v-divider />
              <v-toolbar flat dense>
                <v-spacer />
                <v-btn color="primary" @click="newContract()"><v-icon left >mdi-plus-circle</v-icon>次の契約作成</v-btn>
              </v-toolbar>

            <v-container>
              <!-- future contruct -->
              <v-card class="mx-1">
                <v-toolbar flat>
                  <v-toolbar-title>先の契約</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-expansion-panels>
                  <template v-for="oldPlan in company.end_user_plan">
                    <v-expansion-panel v-if="company.end_user_plan.length >= 1 && oldPlan.id != currentPlanId && oldPlan.start_contract_date > today" :key="oldPlan.id">
                      <v-expansion-panel-header>
                        <span class="d-flex align-center body-1">
                          <img v-if="oldPlan.plan_mst.contract_model === 'point'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1">
                          <img v-else-if="oldPlan.plan_mst.contract_model === 'limit'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1">
                          <img v-else-if="oldPlan.plan_mst.contract_model === 'unlimit'" src="@/assets/ico/ico_unlimited.svg" width="22" class="mr-1">
                          {{ oldPlan.plan_mst.plan_name }}
                        </span>
                        <v-spacer />
                        <span class="text-right">{{ oldPlan.start_contract_date }} 〜 {{ oldPlan.end_contract_date }}</span>
                        <div class="text-center flex-grow-0 px-2">
                          <v-btn icon elevation="0" @click.stop="copyContract(oldPlan)" small><v-icon small >mdi-content-copy</v-icon></v-btn>
                          <v-btn icon elevation="0" @click.stop="editContract(oldPlan)" small><v-icon small >mdi-pencil</v-icon></v-btn>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card flat>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12" sm="6">
                                <h4>契約開始日</h4>
                                <p>{{ oldPlan.start_contract_date }}</p>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <h4>契約満了日</h4>
                                <p>{{ oldPlan.end_contract_date }}</p>
                              </v-col>
                              <v-col v-if="oldPlan.plan_mst && oldPlan.plan_mst.contract_model == 'point'" cols="12" md="6">
                                <h4 class="">契約ポイント</h4>
                                <p>{{ oldPlan.max_point }}<span class="unit">Pt</span></p>
                              </v-col>
                              <v-col cols="12" md="6">
                                <h4>契約社内メモ<popup-hint>クライアントには表示されません</popup-hint></h4>
                                <pre>{{ oldPlan.contractant_admin_memo }}</pre>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <!-- more -->
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </v-card>
              <!-- active contract -->
              <v-card class="mx-1">
                <v-toolbar flat>
                  <v-toolbar-title>現在有効な契約</v-toolbar-title>
                  <v-spacer />
                  <v-btn v-if="company.current_end_user_plan" text @click="editCurrentContract()"><v-icon left>mdi-pencil</v-icon>編集</v-btn>
                  <v-btn v-if="company.current_end_user_plan" text @click="copyCurrentContract()"><v-icon left>mdi-content-copy</v-icon>複製</v-btn>
                  <v-dialog max-width="800" v-if="company.end_user_plan && company.end_user_plan.length > 1" scrollable>
                    <v-card>
                        <v-expansion-panels>
                          <template v-for="oldPlan in company.end_user_plan">
                            <v-expansion-panel v-if="company.end_user_plan.length > 1 && oldPlan.id != currentPlanId" :key="oldPlan.id">
                              <v-expansion-panel-header>
                                <span class="d-flex align-center body-1">
                                  <img v-if="oldPlan.plan_mst.contract_model === 'point'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1">
                                  <img v-else-if="oldPlan.plan_mst.contract_model === 'limit'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1">
                                  <img v-else-if="oldPlan.plan_mst.contract_model === 'unlimit'" src="@/assets/ico/ico_unlimited.svg" width="22" class="mr-1">
                                  {{ oldPlan.plan_mst.plan_name }}
                                </span>
                                <v-spacer />
                                <span class="text-right">{{ oldPlan.start_contract_date }} 〜 {{ oldPlan.end_contract_date }}</span>
                                <div class="text-center flex-grow-0 px-2">
                                  <v-btn icon elevation="0" @click.stop="copyContract(oldPlan)" small><v-icon small >mdi-content-copy</v-icon></v-btn>
                                  <v-btn icon elevation="0" @click.stop="editContract(oldPlan)" small><v-icon small >mdi-pencil</v-icon></v-btn>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-card flat>
                                  <v-card-text>
                                    <v-row dense>
                                      <v-col cols="12" sm="6">
                                        <h4>契約開始日</h4>
                                        <p>{{ oldPlan.start_contract_date }}</p>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <h4>契約満了日</h4>
                                        <p>{{ oldPlan.end_contract_date }}</p>
                                      </v-col>
                                      <v-col v-if="oldPlan.plan_mst && oldPlan.plan_mst.contract_model == 'point'" cols="12" md="6">
                                        <h4 class="">契約ポイント</h4>
                                        <p>{{ oldPlan.max_point }}<span class="unit">Pt</span></p>
                                      </v-col>
                                      <v-col cols="12" md="6">
                                        <h4>契約社内メモ<popup-hint>クライアントには表示されません</popup-hint></h4>
                                        <pre>{{ oldPlan.contractant_admin_memo }}</pre>
                                      </v-col>
                                      <v-col v-if="oldPlan.plan_mst && oldPlan.plan_mst.contract_model == 'point'" cols="12" md="6">
                                        <h4 class="">利用ポイント</h4>
                                        <p>{{ oldPlan.point_total }}<span class="unit">Pt</span></p>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <!-- more -->

                                </v-card>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </template>
                        </v-expansion-panels>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-divider />
                <v-card-text v-if="company.current_end_user_plan">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <h4>契約プラン名</h4>
                      <p>{{ company.current_end_user_plan.plan_mst.plan_name }}</p>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <h4>プランタイプ</h4>
                      <p v-if="company.current_end_user_plan.plan_mst.contract_model == 'point'" class="d-flex"><img src="@/assets/ico/ico_point.svg" width="20" class="mr-1 align-center" />ポイント契約</p>
                      <p v-if="company.current_end_user_plan.plan_mst.contract_model == 'limit'" class="d-flex">限定契約</p>
                      <p v-if="company.current_end_user_plan.plan_mst.contract_model == 'unlimit'" class="d-flex"><img src="@/assets/ico/ico_unlimited.svg" width="20" class="mr-1 align-center" />無制限契約</p>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <h4>契約開始日</h4>
                      <p>{{ company.current_end_user_plan.start_contract_date }}</p>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <h4>契約満了日</h4>
                      <p>{{ company.current_end_user_plan.end_contract_date }}</p>
                    </v-col>
                    <v-col v-if="company.current_end_user_plan.plan_mst.contract_model == 'point'" cols="12" sm="6">
                      <h4 class="">契約ポイント</h4>
                      <p>{{ company.current_end_user_plan.max_point }}<span class="unit">Pt</span></p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <h4>契約社内メモ<popup-hint>クライアントには表示されません</popup-hint></h4>
                      <pre>{{ company.current_end_user_plan.contractant_admin_memo }}</pre>
                    </v-col>
                    <v-col v-if="company.current_end_user_plan.plan_mst.contract_model == 'point'" cols="12" md="6">
                      <h4 class="">利用ポイント</h4>
                      <p>{{ company.current_end_user_plan.point_total }}<span class="unit">Pt</span></p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- old contract -->
              <v-card class="mx-1">
                <v-toolbar flat>
                  <v-toolbar-title>過去の契約</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-expansion-panels>
                  <template v-for="oldPlan in company.end_user_plan">
                    <v-expansion-panel v-if="company.end_user_plan.length > 1 && oldPlan.id != currentPlanId && oldPlan.end_contract_date < today" :key="oldPlan.id">
                      <v-expansion-panel-header>
                        <span class="d-flex align-center body-1">
                          <img v-if="oldPlan.plan_mst.contract_model === 'point'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1">
                          <img v-else-if="oldPlan.plan_mst.contract_model === 'limit'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1">
                          <img v-else-if="oldPlan.plan_mst.contract_model === 'unlimit'" src="@/assets/ico/ico_unlimited.svg" width="22" class="mr-1">
                          {{ oldPlan.plan_mst.plan_name }}
                        </span>
                        <v-spacer />
                        <span class="text-right">{{ oldPlan.start_contract_date }} 〜 {{ oldPlan.end_contract_date }}</span>
                        <div class="text-center flex-grow-0 px-2">
                          <v-btn icon elevation="0" @click.stop="copyContract(oldPlan)" small><v-icon small >mdi-content-copy</v-icon></v-btn>
                          <v-btn icon elevation="0" @click.stop="editContract(oldPlan)" small><v-icon small >mdi-pencil</v-icon></v-btn>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card flat>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12" sm="6">
                                <h4>契約開始日</h4>
                                <p>{{ oldPlan.start_contract_date }}</p>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <h4>契約満了日</h4>
                                <p>{{ oldPlan.end_contract_date }}</p>
                              </v-col>
                              <v-col v-if="oldPlan.plan_mst && oldPlan.plan_mst.contract_model == 'point'" cols="12" md="6">
                                <h4 class="">契約ポイント</h4>
                                <p>{{ oldPlan.max_point }}<span class="unit">Pt</span></p>
                              </v-col>
                              <v-col cols="12" md="6">
                                <h4>契約社内メモ<popup-hint>クライアントには表示されません</popup-hint></h4>
                                <pre>{{ oldPlan.contractant_admin_memo }}</pre>
                              </v-col>
                              <v-col v-if="oldPlan.plan_mst && oldPlan.plan_mst.contract_model == 'point'" cols="12" md="6">
                                <h4 class="">利用ポイント</h4>
                                <p>{{ oldPlan.point_total }}<span class="unit">Pt</span></p>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <!-- more -->
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </v-card>
            </v-container>
          </v-tab-item>

          <!-- tab contents accounts -->
          <v-tab-item>
            <v-divider />
            <v-toolbar flat dense>
              <v-spacer />
              <v-btn color="primary" @click="$router.push({name: 'admin:client_new_login', params: { end_id: currentClientId } })"><v-icon left>mdi-account-plus</v-icon>新規登録</v-btn>
            </v-toolbar>
            <v-container>
            <v-card class="mx-1">
              <v-data-table :headers="accounts_headers" hide-default-footer :items-per-page="accounts.length" :items="accounts" @click:row="clickAccount">
                <template v-slot:[`item.photo`]="{ item }">
                  <LoginAvatar :user="item" :size="42" class="mt-1 mb-1" />
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div class="d-flex justify-space-between">
                    <ruby>{{ item.name }}<rt>{{ item.kana }}</rt></ruby>
                    <div>
                      <v-tooltip top v-if="item.email">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon @click.stop="copyClipboard(item.email)"><v-icon small v-bind="attrs" v-on="on">mdi-email</v-icon></v-btn>
                        </template>
                        <span>{{item.email}}</span>
                      </v-tooltip>
                      <v-tooltip top v-if="item.phone">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon @click.stop="copyClipboard(item.phone)"><v-icon small v-bind="attrs" v-on="on">mdi-cellphone</v-icon></v-btn>
                        </template>
                        <span>{{item.phone}}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.create_at`]="{ item }">{{ item.create_at | moment("YYYY/MM/DD HH:mm") }}</template>
                <template v-slot:[`item.last_login_at`]="{ item }">{{ item.last_login_at | moment("YYYY/MM/DD HH:mm") }}</template>
              </v-data-table>
            </v-card>
            </v-container>
          </v-tab-item>
        </v-tabs>
        <new-request ref="new_request" v-bind:endId="currentClientId"></new-request>
        <contract-dialog ref="contract_dialog" :planProp="planForDialog" :mode="contractMode"></contract-dialog>
        <message-bar ref="message" />
    </div>
</template>

<script>
import ContractDialog from '../../components/ContractDialog.vue'
import NewRequest from '../../components/NewRequest.vue'
import PopupHint from '../../components/PopupHint.vue'
import axios from '@/plugins/axios'
import moment from 'moment'
import MessageBar from '../../components/MessageBar.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  components: { PopupHint, NewRequest, ContractDialog, MessageBar, LoginAvatar },
  name: 'Client',
  data: () => ({
    isLoading: false,
    showAccounts: false,
    selectedTabs: 0,
    contractMode: '',
    historyExpand: [false, false],
    currentClientId: '',
    currentPlanId: '',
    planForDialog: {},
    company: {},
    managers: {
      main: {
        photo: {
          file_url: ''
        }
      },
      sub: []
    },
    end_user_id: '',
    end_company_name: '',
    accounts_headers: [
      {
        text: '',
        width: 10,
        sortable: false,
        value: 'photo',
        cellClass: 'cell-avatar'
      },
      {
        text: '名前',
        value: 'name'
      },
      {
        text: '登録日',
        value: 'create_at',
        width: '180',
        align: 'center'
      },
      {
        text: '最終ログイン日',
        value: 'last_login_at',
        width: '180',
        align: 'center'
      }
    ],
    accounts: [],
    today: ''
  }),
  created: function () {
    this.currentClientId = '' + this.$route.params.id
    this.today = this.todayDate()
    this.readDataFromAPI()
    if (this.$route.params.show_contract === true) {
      this.selectedTabs = 1
    }
    if (this.$route.params.show_account_list === true) {
      this.selectedTabs = 2
    }
  },
  methods: {
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${this.currentClientId}`)
        .then(res => {
          console.log(res.data)
          this.company = res.data
          this.managers.main = this.company.primary_contact_login
          // secondary contact persons ↓
          this.managers.sub = []
          for (var i = 1; i <= 25; i++) {
            var num = this.leadingZero(i)
            if (this.company[`secondary_contact_${num}_login`]) {
              if (this.company[`secondary_contact_${num}_login`].photo == null) {
                this.company[`secondary_contact_${num}_login`].photo = { file_url: '' }
              }
              this.managers.sub.push(this.company[`secondary_contact_${num}_login`])
            }
          }
          this.end_user_id = this.company.id
          this.end_company_name = this.company.end_company_name
          this.accounts = this.company.logins
          // sorting services to category of current active plan
          if (this.company.current_end_user_plan) {
            this.currentPlanId = this.company.current_end_user_plan.id
            this.company.current_end_user_plan.plan_mst.services = this.sortServicesToCategory(this.company.current_end_user_plan.plan_mst.plan_mst_service)
          }
          // sorting services to category of past contracts
          this.company.end_user_plan.forEach((v) => {
            v.plan_mst.services = this.sortServicesToCategory(v.plan_mst.plan_mst_service)
          })
          document.title = document.title.replace('クライアント詳細', this.end_company_name)
        })
        .catch(err => {
          console.log(err)
        })
    },
    leadingZero: function (num) {
      var s = num + ''
      while (s.length < 2) s = '0' + s
      return s
    },
    sortServicesToCategory: function (planMstServices) {
      const map = new Map()
      planMstServices.forEach((va) => {
        var category = ''
        if (va.point_mst?.point_mst_category) {
          category = va.point_mst.point_mst_category.name
        } else {
          category = '未分類'
        }

        var arr
        if (map.has(category)) {
          arr = map.get(category)
          arr.push(va)
        } else {
          arr = []
          arr.push(va)
        }
        arr.sort((a, b) => (a.point_mst.service > b.point_mst.service) ? 1 : ((b.point_mst.service > a.point_mst.service) ? -1 : 0))
        map.set(category, arr)
      })
      return new Map([...map.entries()].sort())
      // console.log(this.company.current_end_user_plan.plan_mst)
    },
    getPlanUnit: function (typeId) {
      let unit = ''
      if (typeId === 'point') unit = 'pt'
      else if (typeId === 'unlimit') unit = '∞'
      else if (typeId === 'limit') unit = '回'
      return unit
    },
    onButtonClick () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    addNewRequest (id) { // open new request dialog (component)
      this.$refs.new_request.open()
    },
    newContract () {
      this.planForDialog = {
        id: '',
        plan_mst: {},
        start_contract_date: '',
        end_contract_date: '',
        points: '',
        contractant_admin_memo: ''
      }
      this.contractMode = 'new'
      this.$refs.contract_dialog.open()
    },
    editContract (plan) {
      this.contractMode = 'edit'
      this.planForDialog = plan
      this.$refs.contract_dialog.open()
    },
    copyContract (plan) {
      this.contractMode = 'copy'
      this.planForDialog = { ...plan }
      this.$refs.contract_dialog.open()
    },
    editCurrentContract () {
      this.contractMode = 'edit'
      this.planForDialog = { ...this.company.current_end_user_plan }
      this.$refs.contract_dialog.open()
    },
    copyCurrentContract () {
      this.contractMode = 'copy'
      this.planForDialog = { ...this.company.current_end_user_plan }
      this.$refs.contract_dialog.open()
    },
    deleteContract () {
      // this.$refs.new_contract.open()
    },
    clickAccount: function (value) {
      const uid = value.id
      if (uid) this.$router.push({ name: 'admin:client_edit_login', params: { end_id: this.currentClientId, id: uid } })
    },
    diffDate: function (expireDate, today) {
      return (moment(expireDate).diff(today, 'days'))
    },
    copyClipboard (str) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(str)
        this.$refs.message.show(`${str}をクリップボードにコピーしました`)
      }
    },
    todayDate: function () {
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      return [year, month, day].join('-')
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
  main .v-card{
    margin-bottom: 20px;
  }

  .period{
    margin-left: 2em;
  }
  ::v-deep .cell-avatar{
    padding-right: 0!important;
  }
</style>

<template>
    <v-dialog persistent v-model="isShow" max-width="600px">
      <v-card>
        <v-card-title v-if="mode=='new' || mode=='copy'">契約の作成</v-card-title>
        <v-card-title v-else>契約の編集</v-card-title>
        <v-divider />
        <v-card-text>
            <v-form ref="form" v-model="isFormValid" lazy-validation>
              <v-container>
              <v-row dense>
                <v-col cols="12" md="12" class="d-flex align-end">
                  <v-select :rules="[inputRules.required]" class="required" label="契約保守プラン" v-model="planProp.plan_mst" :items="plan" item-text="plan_name" hide-details return-object>
                    <template v-slot:item="{ item }">
                      <img v-if="item.contract_model == 'point'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1" />
                      <img v-else src="@/assets/ico/ico_unlimited.svg" width="22" class="mr-1" />
                      {{ item.plan_name }}
                    </template>
                  </v-select>
                    <v-dialog v-model="isDetailShow" max-width="500px">
                      <v-card>
                        <v-card-title>プラン詳細</v-card-title>
                        <v-divider />
                        <v-card-text v-if="planProp.plan_mst">
                          <v-simple-table v-for="[category, services] in planProp.plan_mst.services" :key="category" dense class="my-2">
                              <thead>
                                <tr>
                                  <th>{{ category }}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(service, j) in services" :key="j">
                                  <td v-if="service.point_mst" class="pl-4">{{ service.point_mst.service }}&nbsp;<popup-hint><pre>{{ service.point_mst.point_content }}</pre></popup-hint></td>
                                  <td v-else class="pl-4">名前なし</td>
                                  <td v-if="planProp.plan_mst.contract_model == 'point'" class="text-right pr-4"><strong>{{ service.point }}</strong> {{ getPlanUnit(planProp.plan_mst.contract_model) }}</td>
                                  <td v-if="planProp.plan_mst.contract_model == 'limit'" class="text-right pr-4"><strong>{{ service.max_use_count }}</strong> {{ getPlanUnit(planProp.plan_mst.contract_model) }}</td>
                                  <td v-if="planProp.plan_mst.contract_model == 'unlimit'" class="text-right pr-4"><strong>{{ getPlanUnit(planProp.plan_mst.contract_model) }}</strong></td>
                                </tr>
                              </tbody>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  <v-btn class="ml-2" :disabled="planProp.plan_mst == ''" elevation="0" @click="isDetailShow = true">プラン詳細</v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu v-model="start_contract_date_select" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :rules="[inputRules.required]" v-model="planProp.start_contract_date" label="契約開始日" class="required" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" hide-details="">
                        <template v-slot:append-outer>
                          <popup-hint>他の契約と重複した指定は出来ません</popup-hint>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="planProp.start_contract_date" @input="start_contract_date_select = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu v-model="end_contract_date_select" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :rules="[inputRules.required]" v-model="planProp.end_contract_date" label="契約満了日" class="required" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" hide-details="">
                        <template v-slot:append-outer>
                          <popup-hint>他の契約と重複した指定は出来ません</popup-hint>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="planProp.end_contract_date" @input="end_contract_date_select = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
               <v-col cols="12" md="12" v-if="planProp.plan_mst && planProp.plan_mst.contract_model == 'point'">
                <v-text-field :rules="pointRule" class="required" label="契約ポイント" hide-details v-model="planProp.max_point" suffix="Pt" required></v-text-field>
               </v-col>
                <v-col cols="12" md="12">
                  <v-textarea label="契約社内メモ" rows="1" v-model="planProp.contractant_admin_memo" auto-grow hide-details></v-textarea>
                </v-col>
              </v-row>
              </v-container>
            </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="" text @click="close()">キャンセル</v-btn>
          <v-btn v-promise-btn v-if="mode=='new'" :disabled="!isFormValid" @click="add()" color="primary">追加</v-btn>
          <v-btn v-else-if="mode=='edit'" @click="update()" color="primary">保存</v-btn>
          <v-btn v-promise-btn v-else @click="add()" :disabled="!isFormValid" color="primary">保存</v-btn>
          <v-spacer />
                <confirm-dialog v-if="mode == 'edit'" btnTitle = "削除" @accept="remove">
                  <template v-slot:title>契約情報の削除</template>
                  <template v-slot:message>この契約を削除してもよろしいですか？</template>
                  <template v-slot:activator="{ on }">
                  <v-btn icon @click="on"><v-icon small>mdi-delete-forever</v-icon></v-btn>
                  </template>
                </confirm-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue'
import PopupHint from './PopupHint.vue'
import axios from '@/plugins/axios'
export default {
  components: { PopupHint, ConfirmDialog },
  name: 'ContractDialog',
  props: {
    mode: {
      type: String,
      default: 'new'
    },
    planProp: {
      type: Object,
      default: function () {
        return {
          id: '',
          plan_mst: {},
          start_contract_date: '',
          end_contract_date: '',
          max_point: '',
          contractant_admin_memo: ''
        }
      }
    }
  },
  data: () => ({
    isShow: false,
    isDetailShow: false,
    start_contract_date_select: false,
    end_contract_date_select: false,
    isFormValid: false,
    currentClientId: '',
    pointRule: [ // validation for point plan
      v => !!v || 'Name is required',
      v => (v && v >= 0) || 'must be a number.'
    ],
    form: {
      id: '',
      plan_mst: '',
      start_contract_date: '',
      end_contract_date: '',
      max_point: '',
      contractant_admin_memo: ''
    },
    plan: [],
    accounts: []
  }),
  created: function () {
    this.currentClientId = this.$route.params.id
    this.readFormDataFromAPI()
  },
  computed: {
    nowDate: function () {
      return new Date().toISOString().slice(0, 10)
    },
    selectAccount: function () {
      var rtn = []
      for (const elm of this.accounts) {
        const items = {}
        items.text = elm.name
        items.value = elm.id
        rtn.push(items)
      }
      return rtn
    }
  },
  methods: {
    readFormDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/plan_mst`)
        .then(res => {
          console.log(res.data)
          this.plan = res.data.planMstList
          this.plan.forEach((v) => { // plan details
            v.services = this.sortServicesToCategory(v.plan_mst_service)
          })
          console.log(this.plan)
        }).catch(err => {
          console.log(err)
        })
    },
    sortServicesToCategory: function (planMstServices) {
      const map = new Map()
      planMstServices.forEach((va) => {
        var category = ''
        if (va.point_mst?.point_mst_category) {
          category = va.point_mst.point_mst_category.name
        } else {
          category = '未分類'
        }

        var arr
        if (map.has(category)) {
          arr = map.get(category)
          arr.push(va)
        } else {
          arr = []
          arr.push(va)
        }
        arr.sort((a, b) => (a.point_mst.service > b.point_mst.service) ? 1 : ((b.point_mst.service > a.point_mst.service) ? -1 : 0))
        map.set(category, arr)
      })
      return new Map([...map.entries()].sort())
      // console.log(this.company.current_end_user_plan.plan_mst)
    },
    getPlanUnit: function (typeId) {
      let unit = ''
      if (typeId === 'point') unit = 'pt'
      else if (typeId === 'unlimit') unit = '∞'
      else if (typeId === 'limit') unit = '回'
      return unit
    },
    add: function () {
      console.info('add')
      const _this = this
      _this.form = _this.planProp
      console.log(_this.form)
      if (_this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'POST')
        formData.append('plan_id', _this.form.plan_mst.id)
        formData.append('end_user_id', _this.currentClientId)
        formData.append('start_contract_date', _this.form.start_contract_date)
        formData.append('end_contract_date', _this.form.end_contract_date)
        formData.append('max_point', isNaN(_this.form.max_point) || _this.form.max_point === null ? '' : _this.form.max_point)
        formData.append('contractant_admin_memo', _this.form.contractant_admin_memo === null ? '' : _this.form.contractant_admin_memo)
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user_plan`, formData)
            .then((res) => {
              console.log(res.data)
              _this.$parent.readDataFromAPI()
              resolve(res)
              _this.close()
            })
            .catch((err) => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
              _this.close()
            })
        })
      }
    },
    update: function () {
      console.info('update')
      const _this = this
      _this.form = _this.planProp
      if (_this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('self_id', _this.planProp.id)
        formData.append('plan_id', _this.form.plan_mst.id)
        formData.append('end_user_id', _this.currentClientId)
        formData.append('start_contract_date', _this.form.start_contract_date)
        formData.append('end_contract_date', _this.form.end_contract_date)
        formData.append('max_point', isNaN(_this.form.max_point) || _this.form.max_point === null ? '' : _this.form.max_point)
        formData.append('contractant_admin_memo', _this.form.contractant_admin_memo === null ? '' : _this.form.contractant_admin_memo)
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user_plan/${_this.planProp.id}`, formData)
          .then((res) => {
            console.log(res.data)
            this.$parent.readDataFromAPI()
            this.close()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
      }
    },
    remove: function () {
      console.info('remove')
      const _this = this
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user_plan/${_this.planProp.id}`)
        .then(res => {
          _this.$store.commit('setFlashSuccess', '契約を削除しました。')
          this.$parent.readDataFromAPI()
          this.close()
        })
        .catch(err => {
          _this.$store.commit('setFlashError', '削除することができませんでした。')
          console.log(err)
        })
    },
    open: function () {
      this.isShow = true
    },
    close: function () {
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.v-select-list{
  .v-image{
  }
}
</style>
